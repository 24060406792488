.dashboard-list-item__container {
  background-color: #212121;
  border-radius: 15px;
  box-shadow: 5px -8px 8px #00000047;
  padding: 30px 10px 25px;
  text-align: center;
  border: solid 2px #535353;
}

.dashboard-list-item__question-text h3 {
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 0px;
}

.dashboard-list-item__total-votes {
  margin-top: 20px !important;
  color: grey;
}

.dashboard-list-item__link {
  text-decoration: underline;
  color: #ffffff;
}

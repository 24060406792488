.FollowerChicklet {
    align-items: center;
    background-color: #212121;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: space-between; /* Ensures items are spaced out across the container */
    padding: 15px;
    text-align: left;
    transition: background-color .2s;
    margin-top: 15px;
    margin-left: 0px;
    margin-right: 0px;
}
  
.FollowerChicklet-image-container {
    margin-right: 20px;
}
  
.FollowerChicklet-username {
    flex-grow: 1; /* Allows username to take up any remaining space */
    margin: 0;
    font-size: 18px;
    text-align: left;
}
  
.follow-button {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    display: flex; /* Ensures proper alignment of the icon */
    align-items: center;
}
  
.icon-active, .icon-inactive {
    font-size: 22px; /* Adjust this value as needed to increase the icon size */
}

.icon-active {
    color: #969696; /* Or any color to indicate active/following status */
}
  
.icon-inactive {
    color: #ccc; /* Or any color to indicate inactive/not following status */
}

.dropdown-menu {
    position: absolute;
    background-color: #333;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    z-index: 1;
    min-width: 150px;
  }
  
  .dropdown-menu.right {
    right: 0;
  }
  
  .dropdown-menu.left {
    left: 0;
  }
  
  .dropdown-item {
    padding: 10px 20px;
    color: #fff;
    cursor: pointer;
    white-space: nowrap;
  }
  
  .dropdown-item:hover {
    background-color: #444;
  }

  .loading-text {
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
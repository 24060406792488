input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #ffffff; /* Text color */
  -webkit-box-shadow: 0 0 0px 1000px #00000060 inset; /* Background color */
  transition: background-color 5000s ease-in-out 0s;
}









.payout-history {
  background: #191919;
  border-radius: 8px;
  padding: 20px;
  width: 100%; /* Full width to match click data */
  color: #f5f5f7;
  margin-bottom: 20px;
}

.payout-history h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.payout-history__body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 250px; /* Limit height for scrolling */
  overflow-y: auto;
}

/* Custom scrollbar styling (matching click data) */
.payout-history__body::-webkit-scrollbar {
  width: 6px;
}

.payout-history__body::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 10px;
}

.payout-history__body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.payout-history__row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px 15px;
  background: #121212;
  border-radius: 6px;
}

.payout-history__row p {
  font-size: 1rem;
  color: #cccccc;
  margin: 0;
}

.payout-history__row:nth-child(odd) {
  background: #1e1e1e;
}


/* Billing Wrapper */
.billing-wrapper {
  display: flex;
  flex-wrap: wrap; /* Ensure it wraps on smaller screens */
  gap: 20px;
  width: 100%;
  justify-content: space-between;
}

.billing-left-column {
  flex: 1;
  min-width: 600px; /* Ensure it doesn't shrink too much */
  display: flex;
  flex-direction: column;
  gap: 20px;
}














.checkout-card {
  padding: 20px;
  border-radius: 10px;
  background-color: #1e1e1e; /* Dark background to match your theme */
  text-align: center;
}

.checkout-card h3 {
  font-size: 1rem;
  color: #999; /* Lighter color for secondary text */
}


.signup-page__captcha-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  z-index: 9999; /* Make sure it appears above other elements */
}

.signup-page__captcha-overlay .g-recaptcha {
  transform: scale(1.2); /* Adjust scale if you want it larger */
}


.checkout-divider {
  margin: 15px 0;
  height: 1px;
  background-color: #333;
}

.checkout-breakdown {
  font-size: 1rem;
  color: #ccc; /* Light color for breakdown text */
  margin-bottom: 15px;
}

.checkout-breakdown p {
  margin: 5px 0;
}

.checkout-breakdown p:last-child {
  font-size: 1.3rem;
  font-weight: bold;
  color: #ffffff; /* Highlighted color for net balance */
}

.signup-page__custom-submit-button {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #0066ff; /* Blue button color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.signup-page__custom-submit-button:hover {
  background-color: #0055cc; /* Darker blue on hover */
}



.signup-page__subtext {
  color: #666;
    font-size: .9rem;
    margin-bottom: 12px;
}

.signup-page__csv-viewer{
  width: 100%;
}


.signup-page__form-section-billing {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-page__cards {
  display: flex;
    gap: 20px;
}

.signup-page__button-wrapper {
  background: #191919;
    border-radius: 8px;
    /* margin: 20px; */
    max-width: 350px;
    min-width: 400px;
    padding: 30px 30px 20px;
    text-align: left;
}

.paypal-image-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 100%;
}

.paypal-image {
  width: 100%; /* Adjust the size as needed */
  max-width: 250px; /* Optional: limit the button's maximum width */
  height: auto;
  display: block;
}


.paypal-image-button img {
  max-width: 250px;
}






.custom-verify-checkbox {
  align-items: flex-start;
    display: flex;
    margin-bottom: 10px;
}

.custom-verify-checkbox input[type="checkbox"] {
  appearance: none;
    -webkit-appearance: none;
    background-color: #2c2c2c;
    border: 2px solid #555;
    border-radius: 4px;
    cursor: pointer;
    height: 20px;
    /* margin-right: 8px; */
    outline: none;
    position: relative;
    width: 40px;
}

.custom-verify-checkbox input[type="checkbox"]:checked {
  background-color: #326ef1;
  border-color: #326ef1;
}

.custom-verify-checkbox input[type="checkbox"]::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 2px;
  transform: scale(0) translate(-50%, -50%);
  transition: transform 0.1s ease-in-out;
}

.custom-verify-checkbox input[type="checkbox"]:checked::before {
  transform: scale(1) translate(-50%, -50%);
}

.custom-verify-checkbox label {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  margin-top: 5px;
  margin-left: 10px;
  text-align: left;
}


.custom-password-modal {
  align-items: center;
    background: #000000cf;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1000;
}

.custom-password-modal__content {
  background: #121212;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
}

.custom-password-modal__close {
  background-color: #2b2b2b;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
    margin-top: 5px;
    padding: 8px 16px;
}


.apply-page__form-section {
  min-width: 400px;
  padding: 3rem;
}

.signup-page__input,
.signup-page__textarea {
  width: 92%;
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
  background-color: #0c0c0c;
  border: 1px solid #2a2a2a;
  color: white;
  font-family: 'Poppins', sans-serif; /* Set your preferred font family */
  font-size: 14px; /* Adjust font size as needed */
  resize: vertical; /* Optional: allow vertical resizing */
}

.signup-page__textarea {
  font-family: 'Poppins', sans-serif; /* Ensure textarea has the same font */
  font-size: 14px;
  line-height: 1.5;
  color: white;
}






.apply__container{
  background-color: #171717;
  margin-right: auto;
  border-radius: 8px;
}

.button-group{
  display: flex;
  flex-wrap: wrap;
  
}


.social-media-field {
  display: flex;
  align-items: center;
  gap: 10px;
}

.delete-social-button {
  background: none;
  border: none;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: 18px;
}

.delete-social-button:hover {
  color: rgb(255, 92, 92);
}



.button-group button {
  height: 60px;
  width: 139px;
  margin: 5px;
  padding: 10px 20px;
  background-color: #2a2a2a;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.button-group button.active {
  background-color: #0957ff;
  color: white;
}

.button-group button:hover {
  background-color: #1d4ed8;
}



.signup-page__container {
  min-height: 100vh;
  display: flex;
  color: white;
}

.signup-page__form-section {
  width: 30%;
  min-width: 400px;
  padding: 3rem;
}

.signup-page__form-wrapper {
  max-width: 28rem;
  margin: 0 auto;
  text-align: left;
}



.signup-page__form-wrapper img {
  width: 100%;
  max-width: 150px;
  margin-bottom: 20px;
}

.signup-page__heading {
  font-size: 1.875rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.signup-page__form-group {
  margin-bottom: 1rem;
}

.signup-page__label {
  display: block;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  color: #D1D5DB;
}

.signup-page__custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #0c0c0c;
  border: 1px solid #2a2a2a;
  border-radius: 0.5rem;
  padding: 0.8rem 1rem;
  color: white;
  cursor: pointer;
  width: 100%;
}

.signup-page__custom-select:focus {
  outline: none;
  border-color: #3B82F6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.25);
}

/* Optional: Add a custom arrow */
.signup-page__custom-select::after {
  content: "▼";
  position: absolute;
  right: 10px;
  pointer-events: none;
}

.signup-page__input,
.signup-page__select {
  width: 100%;
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
  background-color: #0c0c0c;
  border: 1px solid #2a2a2a;
  color: white;
}


.signup-page__form-section-centerd{
  margin: 0 auto;
  width: 100%;
  margin-top: 60px;
  padding: 20px;
}

.signup-page__input[type="date"] {
  color-scheme: dark;
}

.signup-page__input:focus,
.signup-page__select:focus {
  outline: none;
  border-color: #3B82F6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.25);
}

.signup-page__name-fields {
  display: flex;
  gap: 1rem;
}

.signup-page__password-input {
  position: relative;
}


.signup-page__image-section h1{
  position: relative;
    font-size: 36px;
    text-align: left;
    height: 50px;
    margin-top: 0px;
    margin-bottom: -90px;
    z-index: 1000000;
    padding: 20px;
    margin-left: 20px;
}

.signup-page__image-section p{
  position: relative;
    font-size: 24px;
    text-align: left;
    height: 50px;
    margin-top: 60px;
    margin-bottom: -150px;
    z-index: 1000000;
    padding: 20px;
    margin-left: 20px;
    width: 600px;
}

.signup-page__password-input button {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: white;
}

.signup-page__submit-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #0957ff;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.signup-page__submit-button:hover {
  background-color: #1D4ED8;
}

.signup-page__signin-link {
  margin-top: 2rem;
  text-align: center;
  color: #9CA3AF;
}

.signup-page__signin-link a {
  color: #3B82F6;
  text-decoration: none;
}

.signup-page__signin-link a:hover {
  color: #60A5FA;
}

.signup-page__image-section {
  display: none;
  width: 100%;
  background-color: #0a0a0a;
}



.signup-page__image-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 1024px) {
  .signup-page__image-section {
    display: block;
  }


  .signup-page__form-section {
    width: 30%;
    min-width: 0px;
    padding: 3rem;
  }
  
}

@media (max-width: 1023px) {
  .signup-page__form-section {
    width: 100%;
  }

  .signup-page__form-section {
    width: 100%;
    min-width: 0px;
    padding: 3rem;
  }
}

@media (max-width: 640px) {
  .signup-page__form-section {
    padding: 1.5rem;
  }

  .signup-page__name-fields {
    grid-template-columns: 1fr;
  }

  .signup-page__form-section {
    width: 100%;
    padding: 3rem;
  }
}

.signup-page__custom-submit-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #0252ff;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  margin: 1rem 0; /* Add space above and below */
  display: block; /* Center alignment */
  margin-bottom: 10px;
  margin-top: 20px;
}

.signup-page__link-button{
  font-size: 16px;
  color: #9CA3AF;
}

.signup-page__custom-submit-button:hover {
  background-color: #1D4ED8;
}

.signup-page__custom-signin-link {
  text-align: center;
  color: #9CA3AF;
  margin: 0;
  padding: 0.5rem 0;
}

.signup-page__custom-signin-link a {
  color: #0b69ff;
  text-decoration: none;
}

.signup-page__custom-signin-link a:hover {
  color: #60A5FA;
}



.signup-page__input,
.signup-page__select,
.signup-page__custom-select {
  width: 100%;
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
  background-color: #0c0c0c;
  border: 1px solid #2a2a2a;
  color: white;
  box-sizing: border-box; /* Ensure consistent width */
}













.paypal-email-input {
  background-color: #1e1e1e;
  color: #ffffff;
  border: 1px solid #444;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  margin-bottom: 10px;
}

.paypal-email-input:disabled {
  background-color: #2c2c2c;
  color: #888;
  cursor: not-allowed;
}

.paypal-save-button {
  align-items: center;
    background-color: #0c2c63;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-size: 16px;
    gap: 8px;
    padding: 10px 15px;
}


.paypal-save-button:disabled {
  background-color: #555;
  cursor: not-allowed;
}


.signup-page__title{
font-family: poppins;
font-size: 26px;
margin: 0px;
margin-bottom: 15px;

}


.signup-page__subtitle{

  font-family: poppins;
  font-size: 20px;
  margin: 0px;
  margin-bottom: 20px;
  margin-top: 10px;
}








@media (max-width: 768px) {
  .signup-page__form-section {
    background-image: url('../assets/mobile-login-bg.jpg'); /* Use the mobile background */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}













/* Mobile Styles */
@media (max-width: 768px) {
  /* Ensure the main wrapper stacks cards */
  .billing-wrapper {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center cards */
    gap: 20px; /* Add spacing between cards */
    padding: 10px;
  }

  /* Ensure cards are full width */
  .signup-page__cards {
    flex-direction: column; /* Stack cards */
    width: 100%; /* Full width of the container */
    gap: 20px; /* Add spacing between cards */
  }

  .signup-page__button-wrapper {
    width: 100%; /* Full width of the viewport */
    max-width: 68%;
    padding: 20px;
  }

  .credit-card {
    padding: 20px;
  }

  .credit-card__header,
  .credit-card__footer {
    justify-content: center; /* Center-align elements in the header and footer */
  }

  .credit-card__content {
    text-align: left; /* Center-align text for better readability */
  }

  .credit-card__paypal-logo {
    width: 30px; /* Adjust logo size for mobile */
  }

  /* Reduce the size of the chip */
  .credit-card__chip {
    width: 30px;
    margin-top: -200px;
  }

  /* CSV Viewer */
  .signup-page__csv-viewer {
    width: 100%; /* Ensure it fits mobile screens */
    margin: 0 auto; /* Center the viewer */
  }

  /* Payout History Section */
  .payout-history {
    width: 100%; /* Full width for better alignment */
    max-width: 400px;
    margin: 0 auto; /* Center align */
    padding: 20px;
  }

  /* Adjust heading sizes */
  .payout-history h3,
  .credit-card__content h3 {
    font-size: 1.1rem;
  }

  /* Input and Buttons */
  .paypal-image-button {
    width: 100%; /* Full width for mobile */
    max-width: 300px; /* Limit max width */
    margin: 0 auto;
  }

  .paypal-image {
    max-width: 200px; /* Scale button size for smaller screens */
    height: auto;
  }

  .signup-page__custom-submit-button {
    width: 90%; /* Reduce button width slightly for better spacing */
    margin: 10px auto;
  }
}









.signup-page__form-section-billing {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-page__cards {
  display: flex;
  gap: 20px;
}

.signup-page__button-wrapper {
  background: #191919;
  border-radius: 8px;
  max-width: 350px;
  min-width: 400px;
  padding: 30px 30px 20px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
}

.credit-card {
  background: url('../assets/creditcard-bg.png') no-repeat center top;
  background-color: #191919;
  border-radius: 10px;
  color: #f5f5f7;
  min-width: 400px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  text-align: left;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  background-size: cover;
}


.credit-card--disabled {
  filter: grayscale(100%);
  opacity: 0.6;
}

.credit-card__header {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.credit-card__paypal-logo {
  width: 40px;
  height: auto;
}

.credit-card__content {
  margin-top: 10px;
}

.credit-card__content h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
}

.credit-card__content p {
  font-size: 1rem;
  color: #dddddd;
  margin: 5px 0;
}

.credit-card .checkout-divider {
  margin: 10px 0;
  height: 1px;
  background-color: #75798b;
}

.credit-card .net-balance {
  color: #fff;
    font-size: 1.3rem;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 0px;
}

.credit-card__footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
    margin-top: 0px;
}

.credit-card__chip {
  height: auto;
  width: 40px;
  margin-top: -240px;
}


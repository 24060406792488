/* Modal Backdrop */
.options-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal Container */
  .options-modal-container {
    background: #1e1e1e;
    color: #f5f5f5;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  
  /* Header */
  .options-modal-header h2 {
    margin: 0;
    font-size: 1.5rem;
    border-bottom: 1px solid #444;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  /* Body */
  .options-modal-body {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .options-modal-field {
    display: flex;
    flex-direction: column;
  }
  
  .options-modal-field label {
    font-size: 0.9rem;
    color: #ccc;
    margin-bottom: 5px;
  }
  
  .options-modal-field input,
  .options-modal-field select,
  .options-modal-field textarea {
    background: #2e2e2e;
    color: #f5f5f5;
    border: 1px solid #444;
    border-radius: 4px;
    padding: 10px;
    font-size: 1rem;
  }
  
  .options-modal-field input[type="checkbox"] {
    width: 20px;
    height: 20px;
  }
  
  /* Footer */
  .options-modal-footer {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
  }
  
  .options-modal-cancel,
  .options-modal-save {
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 4px;
    border: none;
    cursor: pointer;
  }
  
  .options-modal-cancel {
    background: #444;
    color: #f5f5f5;
  }
  
  .options-modal-save {
    background: #007bff;
    color: #fff;
  }
  
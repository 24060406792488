.mode-button {
    flex: 1;
    margin: 0 5px;
    padding: 10px;
    border-radius: 8px;
    border: none;
    background-color: #555; /* Default grey */
    color: #fff; /* Default white text */
    font-size: 0.9em;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .mode-button.active {
    background-color: #fff; /* White background for active */
    color: #000; /* Black text for active */
  }
.profile-settings {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    color: #ffffff;
  }
  
  .profile-settings__header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .profile-settings__back-button {
    background: none;
    border: none;
    color: #ffffff;
    font-size: 1rem;
    cursor: pointer;
    margin-right: 1rem;
  }
  
  .profile-settings h2 {
    margin: 0;
    font-size: 1.8rem;
  }
  
  .profile-settings__form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .profile-settings__section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .profile-settings__section label {
    color: #b0b0b0;
  }
  
  .profile-settings__section textarea,
  .profile-settings__section input[type="text"] {
    padding: 0.75rem;
    border-radius: 4px;
    border: 1px solid #444;
    background-color: #2a2a2a;
    color: #ffffff;
    font-size: 1rem;
  }
  
  .profile-settings__subscription {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2a2a2a;
    padding: 1rem;
    border-radius: 4px;
  }
  
  .profile-settings__subscription button {
    background-color: #0a55f7;
    color: #ffffff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .profile-settings__subscription button:hover {
    background-color: #10e11e;
  }
  
  .profile-settings__advanced-toggle {
    background: none;
    border: none;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    padding: 0;
  }
  
  .profile-settings__advanced-options {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
    padding: 1rem;
    background-color: #2a2a2a;
    border-radius: 4px;
  }
  
  .profile-settings__advanced-options label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
  }
  
  .profile-settings__advanced-options input[type="checkbox"] {
    cursor: pointer;
  }
  
  .profile-settings__delete-account {
    background-color: #e74c3c;
    color: #ffffff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
  }
  
  .profile-settings__delete-account:hover {
    background-color: #c0392b;
  }
  
  .profile-settings__save-button {
    background-color: #16df1d;
    color: #ffffff;
    border: none;
    padding: 0.75rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  

  
  .profile-settings__logout-button {
    background-color: #353535;
    color: #ffffff;
    border: none;
    padding: 0.45rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .profile-settings__logout-button:hover {
    background-color: #0a55f7;
  }
  
  .profile-settings__error {
    color: #e74c3c;
 
  }
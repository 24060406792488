.verify-email-page {
  text-align: center; /* Center the text horizontally */
  color: #f3f4f6; /* Set the text color */
  font-family: 'Poppins', sans-serif; /* Font styling */
}

.verify-email-content {
  background: rgba(33, 33, 33, 0.9); /* Add slight background for better readability */
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
  max-width: 400px; /* Limit content width */
}

.verify-email-content h1 {
  margin-bottom: 1rem; /* Add margin below the heading */
  font-size: 2rem;
}

.verify-email-content p {
  font-size: 1.2rem;
}

.team {
  text-align: center;
  padding: 40px 20px;
  color: #fff;
}

.team h1 {
  font-size: 3rem;
  margin-bottom: 30px;
}

.team-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.team-card {
  display: flex
;
    flex-direction: column;
    background-color: rgb(45 45 45 / 33%);
    border-radius: 15px;
    width: 90%;
    max-width: 600px;
    padding: 20px 30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    text-align: left;
    overflow: hidden;
}

.left-section {
  display: flex;
  flex-direction: column;
}

.profile-image {
  width: 90px;
  height: 90px;
  margin-bottom: 10px;
}

.title h2 {
  margin: 0;
  font-size: 1.8rem;
  font-weight: bold;
}

.title h3 {
  margin: 5px 0;
  font-size: 1.2rem;
  color: #aaa;
  text-align: left;
}

.right-section {
  line-height: 1.6;
}

.favorites {
  margin-top: 20px;
}

.favorites h4 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 5px;
}

.favorites ul {
  list-style: none;
  padding: 0;
}

.favorites ul li {
  margin-bottom: 5px;
}

/* Responsive Design */
@media only screen and (max-width: 768px) {
  .team-card {
    padding: 15px;
  }

  .profile-image {
    width: 100px;
    height: 100px;
  }

  .title h2 {
    font-size: 1.5rem;
  }

  .title h3 {
    font-size: 1rem;
  }

  .favorites h4 {
    font-size: 1rem;
  }
}

/* Modal background overlay */
.profile-create-collection-modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Modal content box */
  .profile-create-collection-modal-content {
    max-height: 80%; /* Constrain height to allow for scrolling */
    overflow-y: auto; /* Enable vertical scrolling */
    background-color: #373737;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 80%;
    max-width: 600px;
    text-align: center;
    margin-bottom: 40px;
  }
  
  /* Poll buttons inside the modal */
  .profile-create-collection-modal-poll-button {
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin: 5px;
    padding: 10px 20px;
    transition: background-color .3s ease;
    width: 93%;
  }
  
  /* Unselected state */
  .profile-create-collection-modal-poll-button-unselected {
    background-color: #232323;
    color: #ffffff;
  }
  
  /* Selected state */
  .profile-create-collection-modal-poll-button-selected {
    background-color: #ffffff;
    color: #262626;
  }
  
  /* Modal buttons for creating or canceling */
  .profile-create-collection-modal-action-button {
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .profile-create-collection-modal-create-button {
    background-color: #14ff0b;
    color: #fff;
  }
  
  .profile-create-collection-modal-cancel-button {
    background-color: #ff4d4d;
    color: #fff;
    margin-left: 10px;
  }
  
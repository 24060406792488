/* General Styling */
.Root-headline {
  text-align: left;
  font-size: 24px;
  font-weight: 400;
  color: #848484;
  margin-bottom: 20px;
}


.Root-disabled {
  color: #7c7c7c; /* Grey out text */
  opacity: 0.5; /* Reduce opacity for visual indication */
}


/* Position the dropdown arrow slightly overlapping and below the profile icon */
.Root-profile-container {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.Root-dropdown-arrow {
  margin-left: 5px;
  position: absolute;
  bottom: -5px; /* Adjust to overlap slightly */
  right: -5px; /* Adjust positioning for alignment */
  font-size: 16px;
  color: #333; /* Adjust color to match theme */
}

/* Dropdown menu styling */
.Root-dropdown-menu {
  background: #121212;
    border: 1px solid #4f4f4f;
    border-radius: 5px;
    box-shadow: 0 2px 5px #0003;
    padding: 10px;
    position: absolute;
    right: 10px;
    top: 60px;
    z-index: 1000;
}

.Root-logout-button {
  background: #f44336;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.Root-logout-button:hover {
  background: #d32f2f;
}




/* Top Bar */
.Root-top-bar-creator {
  width: 100%;
  height: 60px;
  background-color: #121212;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between logo and user info */
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  border-bottom: 1px solid #004eff ;
}

/* Top Bar */
.Root-top-bar-brand {
  width: 100%;
  height: 60px;
  background-color: #121212;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between logo and user info */
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  border-bottom: 1px solid #ff6c00 ;
}

.Root-user-info {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between username and profile icon */
  margin-right: 20px;
}

.Root-username {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
}

/* Logo Image */
.Root-logo-image {
  height: 20px;
  width: auto;
  margin-left: 20px;
}

.Root-monetize-image {
  height: 120px;
  width: auto;
  margin-left: 20px;
  margin-bottom: 0px;
}

.Root-arrow-icon {
  margin-left: 20px;
  margin-right: 10px;
  font-size: 1rem;
  vertical-align: middle;
}

.Root-monetize-paragraph{
  margin-left: 20px;
  width: 220px;
}

/* Dashboard Container */
.Root-dashboard-container {
  display: flex;
  height: calc(100vh - 60px); /* Account for top bar height */
  margin-top: 60px;
  overflow: hidden;
}


/* Sidebar */
.Root-sidebar {
  width: 250px;
  background-color: #191919;
  padding: 20px;
  padding-right: 0px;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.Root-logo {
  margin-left: 20px;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #fff;
}

.Root-nav-menu ul {
  list-style: none;
  padding: 10px;
  border-radius: 8px;
  
}

.Root-nav-item {
  display: flex;
  padding: 10px;
  margin: 5px 0px;
  padding-left: 15px;
  cursor: pointer;
  transition: color 0.3s;
  margin-bottom: 10px;
}

.Root-nav-icon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  object-fit: contain;
  opacity: 0.5;
}

.red-dot {
  background-color: #ff4d4d;
    border-radius: 50%;
    display: inline-block;
    height: 8px;
    margin-left: 8px;
    width: 8px;
    margin-left: 15px;
}

.Root-disabled {
  pointer-events: none; /* Disable interaction */
  opacity: 0.5;         /* Dim the appearance */
  cursor: not-allowed;  /* Show "not-allowed" cursor */
}




/* Active State for Sidebar Items */
.Root-nav-item.Root-active,
.Root-nav-item:hover {
    background-color: #323232;
    color: #ffffff;
    border-radius: 8px;
}


.Root-create-poll {
  width: 90%;
  margin-left: 10px;
  background-color: #004eff;
  border: none;
  color: #fff;
  padding: 15px 20px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s;
  margin-top: 15px;
  
}



.Root-create-poll:hover {
  background-color: #ffffff;
  color: #191919;
}

.Root-bottom-section {
  margin-top: 10px;
}

.Root-monetize-button {
  display: flex;
  background-color: #0066ff;
  padding: 12px;
  margin-left: 15px;
  padding-left: 20px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
}

.Root-arrow{
  margin-left: 0px;
}

.Root-monetize-button:hover {
  background-color: #ffffff;
  color: #191919;
}

/* Main Content */
.Root-main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  overflow-y: auto; /* Allows scrolling for large content */
}

/* Grid Container */
.Root-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.Root-grid-item {
  background-color: #333;
  border-radius: 10px;
  height: 350px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.Root-grid-item:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
}

/* Revenue Section */
.Root-revenue-section {
  margin-top: 20px;
  text-align: center;
  margin-top: -40px;
}

.Root-revenue-amount {
  color: #474747;
  font-size: 48px;
  font-weight: 500;
}

.Root-revenue-period {
  font-size: 18px;
  color: #333;
}
.Root-toggle-dashboard {
  background-color: #191919;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  padding: 6px 12px;
}




.Root-nav-divider{
  height: 1px;
  background-color: #333;
  margin-bottom: 20px;
  margin-top: 20px;
}

.Root-nav-divider-top{
  height: 1px;
  background-color: #333;
  margin-bottom: 20px;
  
}


/* Mobile Styles */
@media (max-width: 768px) {
  /* Adjust sidebar for mobile */
  .Root-sidebar {
    width: 60px; /* Make the sidebar thinner */
    padding: 10px 0; /* Reduce padding */
  }

  /* Sidebar items only show icons */
  .Root-nav-item {
    justify-content: center; /* Center the icons */
    padding: 10px;
  }

  .Root-nav-item span {
    display: none; /* Hide the text */
  }

  .Root-nav-icon {
    width: 25px; /* Larger icons for touch-friendliness */
    height: 25px;
    opacity: 0.5; /* Ensure icons are fully visible */
    margin-right: 0px;
  }

  /* Create button as a square */
  .Root-create-poll-text {
    display: none;
  }

  .Root-create-poll {
    width: 40px;
    height: 40px;
    padding: 0;
    font-size: 24px; /* Larger + symbol */
    justify-content: center; /* Center the content */
  }

  .Root-create-poll-icon {
    display: block;
  }

  /* Main content takes full width */
  .Root-main-content {
    flex: 1;
    margin-left: 0;
    margin-top: 10px; /* Account for top bar */
    padding: 10px;
  }
  .Root-nav-menu ul {
    list-style: none;
    padding: 10px;
    border-radius: 8px;
    padding-left: 5px;
    padding-right: 5px;
}

.Root-revenue-section {
  text-align: center;
  margin-bottom: 10px;
  position: relative;
  writing-mode: vertical-rl; /* Set vertical text direction */
  transform: rotate(180deg); /* Rotate the text */
  font-size: 18px; /* Adjust size for better readability */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}
.Root-grid-container{
  display: block;
}
.Root-revenue-amount {
  color: #474747;
  font-size: 36px; /* Larger font for better visibility */
  font-weight: 500;
  line-height: 1;
}

.Root-revenue-period {
  display: none; /* Keep horizontal alignment for label */
}

.Root-username {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
}

}



/* Show text on desktop */
@media (min-width: 769px) {
  .Root-create-poll-text {
    display: inline; /* Ensure text is visible */
  }

  .Root-create-poll-icon {
    display: none; /* Hide the icon */
  }
}
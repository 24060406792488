.info-container {
    position: relative; /* Allows the popup to position relative to this container */
    display: inline-block; /* Allows multiple info icons to be placed inline */
    margin-left: 5px;
  }
  
  .info-icon {
    width: 16px;
    height: 16px;
    color: #fff;
    background-color: #2b2b2b;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for active state */
    padding-top: 1px;
    padding-left: 1px;
    padding-right: 1px;
  }
  
  .info-icon.active {
    background-color: #fff; /* White background when active */
    color: #000; /* Black text when active */
  }
  
  .info-popup {
    position: absolute; /* Position relative to the info icon */
    top: 30px; /* Adjust to position the popup below the icon */
    left: 50%; /* Center horizontally relative to the icon */
    transform: translateX(-50%); /* Adjust to center */
    background-color: #333;
    padding: 10px;
    border: 1px solid #ddd;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    z-index: 100; /* Ensure the popup appears above other elements */
    width: 200px;
    border-radius: 5px;
    margin: 20px;
    margin-top: 0px;
    font-size: 14px;
  }
  
/* report-component.css */
.report-container {
  margin: 20px auto;
  padding: 20px;
  background-color: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);
  max-width: 800px;
  color: #e0e0e0;
}

.report-title {
  font-size: 1.8em;
  margin-bottom: 20px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
}

.report-fetch-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 1em;
  color: #ffffff;
  background-color: #2b2b2b;
  border: 1px solid #444444;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.report-fetch-button:hover {
  background-color: #444444;
}

.report-option-group {
  margin-bottom: 20px;
  background: #0f0f0f;
  padding: 25px;
  padding-top: 5px;
  border-radius: 8px;
}

.report-option-title {
  color: #fff;
    font-size: 1.4em;
    margin-bottom: 10px;
    text-align: left;
    margin-left: 0px;
}


.report-bar-fill {
  height: 20px;
  border-radius: 8px;
  transition: width 0.3s ease;
}

.report-bar-value {
  position: absolute;
  right: 10px;
  font-size: 0.9em;
  font-weight: bold;
  color: #ffffff;
}







.report-list {
  list-style-type: none;
  padding: 0;
}


.report-sub{
  font-family: 'Poppins';
  font-size: 16;
  font-weight: 400;
}


.report-title{
  font-family: 'Poppins';
  font-size: 28;
  font-weight: 400;
}







.report-bar-container {
  margin-bottom: 15px;
  position: relative;
}

.report-bar-title {
  color: #fff;
    display: block;
    font-size: 1.15em;
    margin-bottom: 5px;
    text-align: left;
}

.report-bar-bg {
  align-items: center;
    background-color: #3a3a3a;
    border-radius: 8px;
    display: flex;
    height: 25px;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
}

.report-bar {
  height: 100%;
  border-radius: 8px;
}

.report-bar-info {
  position: absolute;
  right: 10px; /* Align to the far right of the bar */
  font-size: 0.9em;
  font-weight: bold;
  color: #ffffff;
  z-index: 2;
}
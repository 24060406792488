.affiliate-container {
  padding: 2rem;
  background-color: #191919;
  color: #e0e0e0;
  border-radius: 8px;
  max-width: 800px;
  font-family: 'Poppins';
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
}

.affiliate-title {
  color: #ffffff;
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: left;
}

.affiliate-description {
  margin-bottom: 1.5rem;
  text-align: left;
}

.affiliate-link-container {
  align-items: center;
  display: flex;
  gap: .5rem;
  margin-bottom: 2rem;
  max-width: 500px;
  flex-wrap: wrap;
}

.affiliate-link-input {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #424242;
  background-color: #1e1e1e;
  color: #e0e0e0;
  border-radius: 4px;
  font-size: 1rem;
}

.affiliate-copy-button {
  background-color: #004eff;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
    font-weight: 400;
    padding: .5rem 1rem;
}

.affiliate-copy-button:hover {
  background-color: #3b76ff;
}

.affiliate-stats-section {
  margin-top: 2rem;
}

.affiliate-stats-section h2 {
  margin-top: 2rem;
  text-align: left;
}

.affiliate-stats-section p {
  text-align: left;
}


.affiliate-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.affiliate-card {
  background-color: #1e1e1e;
  padding: 1rem;
  border: 1px solid #424242;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.affiliate-card h3 {
  color: #6494ff;
    margin: 0 0 .5rem;
}

.affiliate-card p {
  margin: 0.25rem 0;
}

.affiliate-error {
  color: #ff5722;
}

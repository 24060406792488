.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0000007a;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto; /* Enable vertical scrolling for the overlay */

}

.modal-content {
  appearance: none;
  -webkit-appearance: none;
  background: #333;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  margin: 20px;
  text-align: left;
  font-family: 'Poppins';
  font-weight: 400;
  max-height: 90%; /* Limit the height to 80% of the viewport */
  overflow-y: auto; /* Enable vertical scrolling */
}

.poll-question-input,
.redirect-input,
.redirect-message {
  padding: 10px;
  margin-bottom: 5px;
  margin-top: 15px;
  border: 1px solid #555;
  border-radius: 5px;
  background: #212121;
  color: #fff;
  width: 90%;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  resize: none; /* Prevent resizing of the textarea */

}

.radio-label {
  appearance: none;
  -webkit-appearance: none;
  display: block;
  margin: 10px 0;
}

.radio-input {
  margin-right: 10px;
  appearance: none;
  -webkit-appearance: none;
}

.poll-options {
  margin-top: 0px;
  margin-bottom: 20px;
}

.show-more-toggle,
.extra-options-toggle {
  color: grey;
  margin-top: 10px;
  cursor: pointer; /* Optional: to show a pointer cursor on hover */
  margin-left: 7px;
}

.poll-category {
  margin-top: 10px;
}

.category-select {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #555;
  border-radius: 5px;
  background: #212121;
  color: #fff;
  font-size: 16px;
}

.modal-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.create-button,
.cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.create-button {
  background: #00d131;
  color: #fff;
  font-size: 16px;
}

.cancel-button {
  background: #ff2e2e;
  color: #fff;
  font-size: 16px;
}

.upload-logo-button{
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #292929;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 12px;
  height: 30px;
  margin-top: 10px;
  margin-left: 0px;
  font-family: 'Poppins';
}

.image-upload-container{
  margin-bottom: 10px;
  margin-top: 15px;
}

.poll-question-input::placeholder,
.redirect-input::placeholder,
.redirect-message::placeholder {
  color: #9b9b9b;
}

.error-message {
  color: #dc3545;
  margin-top: 5px;
}

.extra-options {
  margin-top: 10px;
}

.modal-title {
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 10px;
}

.modal-buttons-stacked {
  flex-direction: column;
  gap: 10px;
}

.poll-auth-method {
  margin-top: 10px;
}

/* Hide default select arrow */
.category-select,
.auth-method-select, .visibility-select  {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #555;
  border-radius: 5px;
  background: #212121;
  color: #fff;
  font-size: 16px;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23fff" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
  padding-right: 30px; /* Add some padding to the right to make room for the arrow */
}

/* Custom arrow for select elements */
.auth-method-select::after, .category-select::after, .visibility-select::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 10px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
  pointer-events: none;
  transform: translateY(-50%);
}

/* Wrapper for custom select styling */
.select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.select-wrapper::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 15px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
  pointer-events: none;
  transform: translateY(-50%);
}

.poll-visibility{
  margin-top: 10px;
}

/* Ensure custom styling applies properly */
.auth-method-select, .category-select, .visibility-select {
  background-color: #212121;
  color: #fff;
  border: 1px solid #555;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.category-select:focus,
.auth-method-select:focus, .visibility-select:focus {
  outline: none;
  border-color: #777;
}

select option {
  padding: 10px; /* Adjust the padding as needed */
}

.custom-labels-toggle {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #fff; /* Match dark theme */
}


.custom-labels-inputs {
  display: flex;
  flex-direction: column; /* Stack vertically */
  gap: 10px;
}





.confidence-labels-title{

  font-family: 'Poppins';
  color: white;
  font-size: 16px;
  margin-bottom: 5px;
  margin-top: 10px;

}

.opinion-labels-title{

  font-family: 'Poppins';
  color: white;
  font-size: 16px;
  margin-bottom: 5px;
  margin-top: 10px;
  margin-left: 0px;


}


/* Custom Checkbox */
.custom-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.custom-checkbox input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  background-color: #2c2c2c;
  border: 2px solid #555;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  position: relative;
  margin-right: 8px;
}

button{
  text-align: center !important;
}

.custom-checkbox input[type="checkbox"]:checked {
  background-color: #326ef1;
  border-color: #326ef1;
}

.custom-checkbox input[type="checkbox"]::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 2px;
  transform: scale(0) translate(-50%, -50%);
  transition: transform 0.1s ease-in-out;
}

.custom-checkbox input[type="checkbox"]:checked::before {
  transform: scale(1) translate(-50%, -50%);
}

.custom-checkbox label {
  font-family: 'Poppins';
  color: white;
  font-size: 16px;
}


/* Custom Radio Button */
.radio-label input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  background-color: #2c2c2c;
  border: 2px solid #555;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
  margin-right: 10px;
}

.radio-label input[type="radio"]:checked {
  background-color: #326ef1;
  border-color: #326ef1;
}

.radio-label input[type="radio"]::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  transform: scale(0) translate(-50%, -50%);
  transition: transform 0.1s ease-in-out;
}

.radio-label input[type="radio"]:checked::before {
  transform: scale(1) translate(-50%, -50%);
}

.radio-label {
  font-family: 'Poppins';
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.poll-logo {
  max-width: 130px;
  max-height: 80px;
  cursor: pointer;
}

.logo-background {
  padding: 8px;
  border-radius: 8px;
  background-color: transparent;
  width: fit-content;
  padding-bottom: 0px;
}

.logo-background.filled {
  background-color: white;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1500;
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-text {
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  color: white;
  font-size: 16px;
  margin-bottom: 20px;
}

.loading-spinner {
  border: 8px solid rgba(255, 255, 255, 0.3);
  border-top: 8px solid #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



/* CreatePollModal.css */

.image-options-title {
  color: white;
    font-weight: 400;
    font-size: 16px;
    margin-left: 3px;
    margin-top: 0px;
    margin-bottom: 5px;
}

.image-action-title {
  font-size: 16px;
    font-weight: 400;
    margin-left: 3px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.options-title {
  margin: 0px;
  font-weight: 400;
}

/* Grid layout for the two sections */
.reports-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

/* Section for "Create New Report" */
.create-report {
  background-color: #191919;
    border-radius: 8px;
    box-shadow: 0 4px 8px #0000001a;
    padding: 20px;
    text-align: left;
}

.create-report h2 {
  margin-bottom: 15px;
  color: #ffffff;
}

.search-box {
  background-color: #0f0f0f;
    border: 1px solid #444;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    margin-bottom: 10px;
    padding: 10px;
    width: 60%;
    text-align: left;
    max-width: 300px;
}

/* Poll grid styles */
.poll-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
}

.poll-card {
  background-color: #0f0f0f;
  border-radius: 8px;
  cursor: pointer;
  padding: 15px;
  text-align: center;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
  font-family: 'Poppins';
}

.poll-card:hover {
  background-color: #0044ff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* Section for "Previous Reports" */
.previous-reports {
  background-color: #191919;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.previous-reports h2 {
  margin-bottom: 15px;
  color: #ffffff;
}

/* Report list styles */
.report-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.report-item {
  background-color: #0f0f0f;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px 15px;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
}

.report-item:hover {
  background-color: #0044ff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}


.previous-report-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
}

.previous-report-card {
  background-color: #0f0f0f;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    font-family: 'Poppins';
}



.previous-report-header {
  align-items: flex-start;
  display: flex
;
  gap: 10px;
}

.previous-report-icon {
  width: 40px;
  height: 40px;
}


  .previous-report-name {
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #fff;
    display: -webkit-box;
    font-size: 16px;
    line-height: 1.4;
    max-height: 2.8em;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px;
  }
  


.previous-report-footer {
  align-items: center;
    display: flex
;
    justify-content: space-between;
    margin-top: auto;
}

.previous-report-actions {
  align-items: center;
    display: flex;
    gap: 10px;
}

.previous-view-btn {
  background-color: #004eff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  width: 60px;
  text-align: center;
}



.previous-delete-btn {
  border: none;
    cursor: pointer;
    background-color: #ff4343;
    border-radius: 5px;
    padding: 5px 10px;
}

.previous-delete-btn img {
  height: 13px;
}

.previous-report-date {
  font-size: 14px;
  color: #aaaaaa;
  margin-top: 10px;
}


@media (max-width: 768px) {
  .reports-grid {
    display: flex;
    flex-direction: column-reverse;
  }

  .search-box {
    background-color: #0f0f0f;
    border: 1px solid #444;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    margin-bottom: 10px;
    max-width: 300px;
    padding: 10px;
    text-align: left;
    width: 90%;
}
}
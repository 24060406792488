.color-bar-homepage__wrapper {
  position: relative;
  width: 80%;
  margin: 20px auto 0;
}

.color-bar-homepage__container {
  margin: 20px auto 0;
  height: 15px;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
}

.color-bar-homepage__segment {
  height: 100%;
  position: relative;
}

.color-bar-homepage__tooltip-wrapper {
  position: absolute;
}

.color-bar-homepage__tooltip {
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 12px;
}

.color-bar-homepage__tooltip-opinion {
  font-weight: bold;
}

.color-bar-homepage__tooltip-timestamp {
  font-size: 10px;
  color: #bbbbbb;
}

.color-bar-homepage__vote-counts {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.color-bar-homepage__vote-count {
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 10px;
}

/* BrandAd.css */

.brand-ad-container {
  font-family: 'Poppins';
  background-color: #121212;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: 100%;
}

.brand-ad-container:hover {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.brand-ad-image-wrapper {
  width: 100%;
  height: 150px; /* Set a fixed height for the image area */
  background-color: #f8f8f8;
  overflow: hidden; /* Ensures the image doesn't overflow the wrapper */
}

.brand-ad-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Crops the image to fit within the wrapper */
  display: block;
}


.brand-ad-content {
  padding: 15px;
  text-align: center;
}

.brand-ad-description {
  color: #ffffff;
    font-size: 16px;
    margin-bottom: 10px;
    text-align: left;
    margin-top: 0px;
}

.brand-ad-footer {
  align-items: center;
    display: flex;
    gap: 5px;
    justify-content: space-between;
}

.brand-ad-sponsor {
  font-size: 12px;
  color: #888;
}

.brand-ad-button {
  background-color: #0071e3;
  color: white;
  border: none;
  padding: 7px 12px;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.brand-ad-button:hover {
  background-color: #005bb5;
}




.brand-ad-description-mockup {
  width: 100%;
  font-size: 16px;
  color: #ffffff;
  background: none;
  border: none;
  text-align: left;
  margin-top: 0;
  margin-bottom: 10px;
  padding: 0;
  outline: none;
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  min-height: 24px; /* Matches single-line height */
  max-height: 200px; /* Optional: Prevent it from growing indefinitely */
  resize: none;
  overflow: hidden;
  box-sizing: border-box; /* Include padding in height calculations */
}

.brand-ad-description-mockup:focus {
  background-color: #2c2c2c;
  padding: 5px;
  border-radius: 4px;
  outline: 1px solid #444;
}

.brand-ad-link-mockup {
  width: 100%;
  font-size: 14px;
  color: #888;
  background: none;
  border: none;
  outline: none;
  margin-bottom: 10px;
  text-align: left;
}

.brand-ad-link-mockup:focus {
  background-color: #2c2c2c;
  padding: 5px;
  border-radius: 4px;
  outline: 1px solid #444;
}

.remove-ad-button {
  align-self: center;
  background-color: #ff0000;
  border: none;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  padding: 3px 20px;
  transition: background-color .3s ease;
}

.remove-ad-button:hover {
  background-color: #b00;
}

.add-ad-button {
  background-color: #2d2d2d;
    border: none;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    padding: 10px 15px;
    transition: background-color .3s ease;
    margin-top: 20px;
    width: 100%;
}

.add-ad-button:hover {
  background-color: #0076eb;
}

.ads-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}


.ad-item-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.brand-ad-sponsor {
  font-size: 12px;
  color: #888;
  font-family: 'Poppins', sans-serif;
}

.brand-ad-divider {
  font-size: 12px;
  color: #888;
  font-family: 'Poppins', sans-serif;
}

.brand-ad-sponsor-static {
  font-size: 12px;
  color: #888;
  font-family: 'Poppins', sans-serif;
}
























/* Ad Type Selector Overlay */
.ad-type-selector-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.665);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.ad-type-selector-container {
  background: #141414;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .323);
    max-width: 730px;
    padding: 20px;
    text-align: center;
    width: 80%;
}

.ad-type-options {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.ad-type-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  background: #212121;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
}

.ad-type-button:hover {
  background: #007bff;
  color: #ffffff;
  border-color: #007bff;
}

.ad-type-button img {
  height: 185px;
    /* margin-bottom: 10px; */
    object-fit: contain;
    width: 185px;
}

.close-ad-type-selector {
  background: #fd0f12;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    margin-top: 10px;
    padding: 8px 10px;
}
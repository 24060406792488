/* CSVViewer.css */
.csv-viewer {
  background-color: #191919;
  border-radius: 8px;
  color: #f5f5f7;
  padding: 15px;
  max-height: 450px;
  overflow-y: auto;
}

/* Custom scrollbar styling */
.csv-viewer::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
}

.csv-viewer::-webkit-scrollbar-track {
    background: #2c2c2c; /* Track color */
    border-radius: 8px;
}

.csv-viewer::-webkit-scrollbar-thumb {
    background-color: #444444; /* Scroll thumb color */
    border-radius: 8px;
    border: 2px solid #2c2c2c; /* Adds space around the thumb */
}

.csv-viewer::-webkit-scrollbar-thumb:hover {
    background-color: #555555; /* Thumb color on hover */
}

.csv-viewer h3 {
    color: #fff;
    margin-bottom: 10px;
    text-align: left;
}

.csv-table {
    width: 100%;
    border-collapse: collapse;
}

.csv-table th, .csv-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #444;
    color: #f5f5f7;
}

.csv-table th {
    background-color: #191919;
    color: #f5f5f7;
    font-weight: 700;
}

.csv-table tr:hover {
    background-color: #444444;
}
  
  .csv-viewer h3 {
    color: #fff;
    margin-bottom: 10px;
    text-align: left;
  }
  
  .csv-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .csv-table th, .csv-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #444;
    color: #f5f5f7;
  }
  
  .csv-table th {
    background-color: #191919;
    color: #f5f5f7;
    font-weight: 700;
  }
  
  .csv-table tr:hover {
    background-color: #444444;
  }
  
.ProfileChicklet {
  align-items: center;
  background-color: #212121;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  display: flex;
  padding: 15px;
  text-align: left;
  transition: background-color .2s;
  margin-top: 15px;
}

.ProfileChicklet-image-container {
  margin-right: 20px;
  margin-left: 5px;
}

.ProfileChicklet-details {
  flex: 1;
  max-width: calc(100% - 85px); /* Adjust based on image size and padding */
  display: inline-block;
  vertical-align: top;
}

.ProfileChicklet-username {
  margin: 0;
  font-size: 18px;
}

.ProfileChicklet-pollCount {
  color: #757575;
    font-size: 16px;
    margin: 0;
    font-weight: 600;
}

.ProfileChicklet-bio {
  margin: 0;
  font-size: 14px;
  color: #ccc;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 95%;
}

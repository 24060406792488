.home-page-poll__container {
  background-color: #212121;
  border-radius: 15px;
  padding: 30px 10px 25px;
  text-align: center;
  border: solid 2px #535353;
  margin-left: 5px;
  margin-right: 5px;
  transition: transform 0.2s ease; /* Add smooth transition */
}

.home-page-poll__container:hover {
  transform: scale(1.05); /* Slightly grow the container on hover */
}

.home-page-poll__question-text h3 {
  font-size: 12px;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 0px;
}

.home-page-poll__total-votes {
  margin-top: 20px !important;
  color: grey;
}

.home-page-poll__link {
  text-decoration: underline;
  color: #ffffff;
}

.campaigns-container {
  padding: 20px;
  padding-left: 0px;
}

.folder-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
  justify-items: center;
}

.add-campaign-folder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-poll-button{
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  padding: 8px 12px;

}



.add-campaign-button {
  background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    padding: 20px 25px;
    transition: background-color .3s ease;
    max-width: 80px;
}



.new-campaign-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.campaign-name-input {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  max-width: 200px;
}

.create-campaign-button,
.cancel-button {
  padding: 8px 12px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.create-campaign-button {
  background-color: #28a745;
  color: #fff;
}

.create-campaign-button:hover {
  background-color: #218838;
}

.cancel-button {
  background-color: #dc3545;
  color: #fff;
}

.cancel-button:hover {
  background-color: #c82333;
}

.campaign-folder {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.campaign-folder:hover {
  transform: scale(1.05);
}

.folder-icon {
  width: 100px;
  height: auto;
  margin-bottom: 10px;
}

.campaign-name {
  word-wrap: break-word;
    color: #ffffff;
    font-size: 14px;
    font-family: poppins;
}

.add-poll-button-container{

  display: flex
  ;
  
      align-content: center;
      justify-content: flex-start;
      align-items: flex-start;


}


.campaign-dropdown {
  align-items: center;
    display: flex
;
    justify-content: flex-start;
    margin: 20px 0;
    position: relative;
    margin-top: 0px;
}

.campaign-select {
  -webkit-appearance: none;
    appearance: none;
    background-color: #212121;
    border: 2px solid #555555;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    max-width: 400px;
    outline: none;
    padding: 10px 15px;
    transition: all .3s ease;
    width: 100%;
}


.campaign-select option {
  background-color: #272727; /* Same background for options */
  color: #ffffff; /* Consistent text color */
}

/* Style dropdown arrow */
.campaign-select::-ms-expand {
  display: none; /* Hide default arrow in IE */
}





.null{
  text-align: left;
}



.campaign-config-bar {
  align-items: center;
  background-color: #191919;
  border-radius: 8px;
  color: #fff;
  display: inline-flex; /* Use inline-flex to size based on content */
  justify-content: flex-start;
  padding: 10px 15px;
  position: sticky;
  top: 0;
  z-index: 10;
  margin-bottom: 20px;
  max-width: fit-content; /* Ensure it only takes as much space as needed */
  gap: 15px; /* Add spacing between elements */
}


.campaign-status {
  display: flex;
  align-items: center;
  gap: 10px;
}

.status-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: pulse 1.5s infinite;
}

.status-indicator.live {
  background-color: #00ff3c;
}

.status-indicator.paused {
  background-color: #dc3545;
  animation: none;
}

@keyframes pulse {
  0%, 100% {
      transform: scale(1);
      opacity: 1;
  }
  50% {
      transform: scale(1.3);
      opacity: 0.7;
  }
}

.status-toggle-button {
  background-color: #333333;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    padding: 8px 12px;
}

.delete-campaign-button{
  background-color: #ff2828;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  padding: 8px 12px;

}

.status-toggle-button:hover {
  background-color: rgb(99, 99, 99);
}

.delete-campaign-button:hover{
  background-color: rgb(255, 0, 0);

}






@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');



/* Home.css */
.home-page * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.navbar * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
    min-height: 100vh;
    overflow-x: hidden;
    background-color: #0f0f0f; /* Fallback background */
    color: white;
    text-align: center;
}

html {
  scroll-behavior: smooth;
}

.nav-links a.active {
  background-color: white;
  color: #0F0F0F;
}



/* Background Wrapper */
.background-wrapper {
  top: 0;
  left: 0;
  width: 100vw;
  background: url('../assets/bg.png') no-repeat center top;
  background-size: contain;
  z-index: -1; /* Push it behind all other content */
}


/* Background Wrapper */
.background-wrapper-2 {
  top: 0;
  left: 0;
  width: 100vw;
  background: url('../assets/bg-brands.png') no-repeat center top;
  background-size: contain;
  z-index: -1; /* Push it behind all other content */
}



.container {
  max-width: 2000px;
  margin: 0 auto; /* Center horizontally */
  padding: 0 20px; /* Optional: Add some horizontal padding */
}


/* Navbar Styles */
.navbar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 50px;
}

.nav-capsule {
  background-color: rgba(255, 255, 255, 0.21);
  border-radius: 50px;
  padding: 12px 10px;
  display: flex;
}

.auth-capsule {
  background-color: rgba(255, 255, 255, 0.21);
  border-radius: 50px;
  padding: 8px 10px;
  display: flex;
  gap: 20px;
}

.home-logo {
  height: 25px;
  margin-right: 70px;
}

/* Nav Links */
.nav-links {
  display: flex;
  gap: 20px;
  list-style: none;
  flex: 1;
  justify-content: center;
}

.nav-links a {
  font-size: 14px;
  text-decoration: none;
  color: white;
  font-weight: 400;
  padding: 6px 20px;
  border-radius: 30px;
  transition: background-color 0.3s, color 0.3s;
}

.nav-links a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Active Nav Link State */
.nav-links a.active {
  background-color: white;
  color: #0F0F0F;
}

/* Auth Buttons */
.auth-capsule button {
  font-size: 14px;
  padding: 6px 20px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

/* Sign Up Button */
.signup {
  background-color: white;
  color: #0F0F0F;
}



/* Log In Button */
.login {
  background-color: rgba(255, 255, 255, 0);
  color: white;
}

.login:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

/* Hero Section */
.hero {
  text-align: center;
  margin-top: 50px;
}

.hero h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500; /* Ensure this matches the imported weight */
  font-size: 60px;
  margin-bottom: 20px;
  line-height: 1.2;
}

/* Hero Section */
.hero-2 {
  text-align: center;
  margin-top: 50px;
}

.hero-2 h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500; /* Ensure this matches the imported weight */
  font-size: 60px;
  margin-bottom: 20px;
  line-height: 1.2;
}

.hero-2 p {
  display: inline-flex;
  font-size: 20px;
  margin-bottom: 40px;
    color: rgba(255, 255, 255, 0.759);
    max-width: 800px;
    text-align: center;
}

.hero p {
  font-size: 20px;
  margin-bottom: 40px;
    color: rgba(255, 255, 255, 0.759);
}

.cta-button {
  padding: 8px 40px;
  background-color: #1414F4;
  border: none;
  border-radius: 30px;
  color: white;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-right: 10px;
}

.demo-button {
  padding: 7px 40px;
  background-color: #00000000;
  border: 2px solid #ffffff84;
  border-radius: 30px;
  color: white;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left: 10px;
}


.cta-button-sections {
  margin-top: 20px;
  padding: 8px 40px;
  background-color: #1414F4;
  border: none;
  border-radius: 30px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cta-button-sections:hover {
  background-color: white;
  color: black;
}

.cta-button:hover {
  background-color: white;
  color: black;
}

.cta-button-container{
  
}

/* Card Container */
.card-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 50px;
  padding: 0 20px;
  padding-bottom: 40px;
}

.card-container img{
  width: 100%;
  max-width: 900px;
}

.card {
  width: 200px;
  height: 350px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 15px;
}


/* Full Width Wrapper */
.full-width-wrapper {
  width: 100vw; /* Full viewport width */
  background-color: #0A0A0A;
  padding: 150px 0;
  margin: 0;
  display: block;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically if needed */
  overflow: hidden;
  padding: 0px 20px;
  padding-top: 150px;
  padding-bottom: 160px;
}



.full-width-wrapper-sections {
  width: 100vw; /* Full viewport width */
  background-color: #0F0F0F;
  padding: 150px 0;
  margin: 0;
  display: block;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically if needed */
  overflow: hidden;
  padding: 0px 20px;

  
}


/* Full Width Wrapper */
.full-width-wrapper-2 {
  width: 100vw; /* Full viewport width */
  background-color: #0F0F0F;
  padding: 150px 0;
  margin: 0;
  display: block;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically if needed */
  overflow: hidden;
  padding: 0px 20px;
  padding-top: 150px;
  padding-bottom: 100px;
}



.full-width-wrapper .custom-grid-container {
  max-width: 1400px;
  margin: 0 auto; /* Centers the container */
  padding: 0 20px; /* Horizontal padding */
  display: flex;
  gap: 30px;
}


.social-icons-container {
  padding: 10px 0px;
  border-radius: 20px;
  max-width: 1360px;
  margin: 0 auto; /* Centers the container */
  display: flex;
    justify-content: space-between;
    gap: 20px;
    /* padding: 20px 20px; */
    
    margin-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
}

.social-icons-container img {
  width: 60px;
  height: 60px;
  border-radius: 50%;      /* Circular icons */
  padding: 5px;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.social-icons-container img:hover {
  transform: scale(1.1);  /* Slight zoom on hover */
}


.partners-container {
  padding: 10px 0px;
  border-radius: 20px;
  max-width: 1000px;
  margin: 0 auto; /* Centers the container */
  display: flex;
  flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    /* padding: 20px 20px; */
    
    margin-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
}

.partners-container img {
  height: 120px;
  padding: 5px;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.partners-container img:hover {
  transform: scale(1.1);  /* Slight zoom on hover */
}

/* Custom Grid Container */
.custom-grid-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 60px;
  display: flex;
  gap: 30px;
}

/* Left Section */
.custom-left-section {
  flex: 1;
}

/* Right Section */
.custom-right-section {
  flex: 0 0 35%;
}

/* Header Styles */
.custom-header {
  margin-bottom: 30px;
  text-align: left;
}

.custom-header h1 {
  text-align: left;
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 20px;
  line-height: 1.2;
}

.custom-header p {
  text-align: left;
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.759);
  line-height: 1.8;
  max-width: 80%;
}

.segment-container img{
  width: 100%;
  
}

/* Steps Grid */
.custom-steps-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

/* Chat Widget */
.chat-widget {
  position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #232323;
    color: #ffffff;
    border-radius: 50px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgb(6 6 6 / 67%);
    transition: all 0.3s ease-in-out;
    z-index: 1000;
}


/* Card Styles */
.custom-card {
  background-color: #0F0F0F;
  padding: 20px;
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
  text-align: left;
}

.custom-card:hover {
  transform: scale(1.025); /* Slightly increase the card size */
}




.create-poll-card {
  width: calc(50% - 15px);
  min-height: 300px;
  background: url('../assets/createpoll.png') no-repeat left bottom;
  background-size: contain;
  background-color: #0F0F0F;

}

.share-card {
  width: calc(50% - 15px);
  min-height: 300px;
  background: url('../assets/shareit.png') no-repeat left bottom;
  background-size: contain;
  background-color: #0F0F0F;


}



.custom-large {
  width: 100%;
  min-height: 300px;
  background: url('../assets/gatheropinions.png') no-repeat left top;
  background-size: contain;
  background-color: #0F0F0F;
}

.custom-money {
  height: 100%;
  background: url('../assets/makemoney.png') no-repeat center top;
  background-size: contain;
  background-color: #0F0F0F;
}

/* Number and Title Styles */
.custom-number {
  font-size: 2rem;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 15px;
    display: inline-block;
    margin-left: 10px;
}

.custom-title {
  font-size: 1.5rem;
  color: #666;
  margin-left: 15px;
}





.gradient-section {
  width: 100%;
  background-size: cover;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: end;
  text-align: left;
  margin: 50px auto;
  padding: 40px;
  max-width: 1360px;
  position: relative;
  padding-top: 0px;
  background-color: #0A0A0A;
  padding-top: 40px;
}

.gradient-section h2 {
  font-size: 32px;
  margin-bottom: 10px;
  color: white;
  text-align: right;
  font-weight: 500;
}

.gradient-section p {
  text-align: right;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.8);
  max-width: 800px;
  line-height: 1.5;
}

.placeholder-image {
  width: 80%;
  height: 500px;
  background-color: white;
  border-radius: 15px;
  margin-top: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
}

.gradient-section img{
  margin-top: 20px;
  width: 100%;
  
}






.gradient-section-2 {
  width: 100%;
  background-size: cover;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  text-align: left;
  margin: 50px auto;
  padding: 40px;
  max-width: 1360px;
  position: relative;
  padding-top: 0px;
  background-color: #0A0A0A;
  padding-top: 40px;
}

.gradient-section-2 h2 {
  font-size: 32px;
  margin-bottom: 10px;
  color: white;
  text-align: left;
  font-weight: 500;
}

.gradient-section-2 p {
  text-align: left;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.8);
  max-width: 800px;
  line-height: 1.5;
}

.placeholder-image-2 {
  width: 80%;
  height: 500px;
  background-color: white;
  border-radius: 15px;
  margin-top: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
}

.gradient-section-2 img{
  margin-top: 20px;
  width: 100%;
}




.gradient-section-3 {
  width: 100%;
  background-size: cover;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: end;
  text-align: left;
  margin: 50px auto;
  padding: 40px;
  max-width: 1360px;
  position: relative;
  padding-top: 0px;
  background-color: #0A0A0A;
  padding-top: 40px;
}

.gradient-section-3 h2 {
  font-size: 32px;
  margin-bottom: 10px;
  color: white;
  text-align: right;
  font-weight: 500;
}

.gradient-section-3 p {
  text-align: right;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.8);
  max-width: 800px;
  line-height: 1.5;
}

.placeholder-image-3 {
  width: 80%;
  height: 500px;
  background-color: white;
  border-radius: 15px;
  margin-top: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
}

.gradient-section-3 img{
  margin-top: 20px;
  width: 100%;
}





.gradient-section-4 {
  width: 100%;
  background-size: cover;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  text-align: left;
  margin: 50px auto;
  padding: 40px;
  max-width: 1360px;
  position: relative;
  padding-top: 0px;
  background-color: #0F0F0F;
  padding-top: 40px;
}

.gradient-section-4 h2 {
  font-size: 32px;
  margin-bottom: 10px;
  color: white;
  text-align: left;
  font-weight: 500;
}

.gradient-section-4 p {
  text-align: left;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.8);
  max-width: 800px;
  line-height: 1.5;
}

.placeholder-image-4 {
  width: 80%;
  height: 500px;
  background-color: white;
  border-radius: 15px;
  margin-top: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
}

.gradient-section-4 img{
  margin-top: 20px;
  width: 100%;
}




/* Footer Section */
.footer {
  font-size: 14px;
  background-color: #0A0A0A;
  padding: 40px 20px;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 50px;
  text-align: center;
}

.footer-container {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center;
  gap: 20px;
}

.footer-logo-section {
  margin-bottom: 10px;
}

.footer-logo {
  height: 20px;
}

.footer-links ul {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 15px;
}

.footer-links a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #FFFFFF;
}

.footer-socials {
  margin-top: 10px; /* Add space between links and socials */
}

.footer-socials img {
  width: 40px;
  height: 40px;
  margin: 0 10px;
  border-radius: 50%; /* Circular icons */
  transition: transform 0.3s ease;
  cursor: pointer;
}



/* Close Button */
.close-lightbox {
  position: absolute;
    top: -30px;
    /* right: 80px; */
    background: transparent;
    color: #fff;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
    transition: color 0.3s ease;
    z-index: 10000000;
}

.close-lightbox:hover {
  color: #ff4d4d;
}


.footer-socials img:hover {
  transform: scale(1.1); /* Zoom effect on hover */
}

.footer-bottom {
  margin-top: 20px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}



/* Hamburger Menu Styles */
.hamburger-menu {
  display: none; /* Hidden on desktop */
  flex-direction: column;
  cursor: pointer;
}

.hamburger-menu span {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px;
  transition: 0.3s;
  border-radius: 20px;
}





/* Responsive Design */
@media (max-width: 768px) {


  .cta-button {
    padding: 8px 40px;
    background-color: #1414F4;
    border: none;
    border-radius: 30px;
    color: white;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .demo-button {
    padding: 7px 40px;
    background-color: #00000000;
    border: 2px solid #ffffff84;
    border-radius: 30px;
    color: rgb(255, 255, 255);
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  

  
 
  .background-wrapper {
    top: 0;
    left: 0;
    width: 100vw;
    background: url('../assets/bg.png') no-repeat center top;
    background-size: contain;
    z-index: -1; /* Push it behind all other content */
  }

 /* Show hamburger menu */
 .hamburger-menu {
  display: flex; /* Show hamburger icon */
}

/* Adjust navbar layout */
.navbar {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.logo {
  margin-right: 0;
}

.bar-container{
  padding: 0 20px;
}



.nav-capsule.open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px;
  left: 0;
  background-color: #0A0A0A;
  padding: 20px 0;
  z-index: 1000;
  align-items: flex-end;
  padding-right: 25px;
  border-radius: 0px;
}





.home-logo {
  height: 20px;
  margin-right: 70px;
}

/* Hide nav links and auth buttons */
.nav-capsule,
.auth-capsule {
  display: none;
}




/* Auth Buttons */
.auth-capsule button {
  font-size: 16px;
  padding: 6px 20px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  margin-top: -5px;
}

/* Sign Up Button */
.signup {
  background-color: white;
  color: #0F0F0F;
}



/* Log In Button */
.login {
  background-color: rgba(255, 255, 255, 0);
  color: white;
}

/* Adjust nav-capsule styles */
.nav-capsule {
  margin: 0;
  width: 100%;
  justify-content: center;
  background-color: transparent;
  padding: 0;
}

/* Adjust nav-links styles */
.nav-links {
  flex-direction: column;
  gap: 0;
  align-items: center;
}

.nav-links li {
  margin: 10px 0;
}

.nav-links a {
  font-size: 18px;
}

/* Adjust auth-capsule styles */
.auth-capsule {
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
  background-color: transparent;
  padding: 0;
}

/* Chat Widget */
.chat-widget {
}





  .custom-grid-container {
      flex-direction: column;
      padding: 30px;
  }

  .custom-left-section {
      max-width: 100%;
  }

  .custom-right-section {
      width: 100%;
  }

  .custom-header h1 {
      font-size: 1.5rem;
      text-align: center;
  }

  .custom-header p {
    font-size: 1rem;
    text-align: center;
}

  .custom-small {
      width: 100%;
  }


  /* Hero Section */
.hero {
  text-align: center;
  margin-top: 30px;
}

.hero h1 {
  font-size: 36px;
  margin-bottom: 20px;
  line-height: 1.2;
}

.hero p {
  font-size: 18px;
  margin-bottom: 40px;
  color: rgba(255, 255, 255, 0.759);
}



.social-icons-container {
  padding: 10px 0px;
  border-radius: 20px;
  margin: 0 auto; /* Centers the container */
  display: flex;
  flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    /* padding: 20px 20px; */
    
    margin-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
}

.social-icons-container img {
  width: 60px;
  height: 60px;
  border-radius: 50%;      /* Circular icons */
  padding: 5px;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.social-icons-container img:hover {
  transform: scale(1.1);  /* Slight zoom on hover */
}


.full-width-wrapper{
  padding-left: 0px;
  padding-right: 0px;
}

.custom-header{
  margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.pinterest-icon {
  display: none;
}



.create-poll-card {
  background: url('../assets/createpoll.png') no-repeat left bottom;
  background-size: contain;
  background-color: #0F0F0F;

}

.share-card {
  background: url('../assets/shareit.png') no-repeat left bottom;
  background-size: contain;
  background-color: #0F0F0F;


}



.custom-large {
  min-height: 300px;

 
  background: url('../assets/gatheropinions-mobile.png') no-repeat center bottom;
  background-size: contain;
  background-color: #0F0F0F;
  
}

.custom-money {
  min-height: 550px;

  background: url('../assets/makemoney-mobile.png') no-repeat center bottom;
  background-size: contain;
  background-color: #0F0F0F;
}





.gradient-section h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: white;
  text-align: center;
  font-weight: 500;
}


.gradient-section p{

  font-size: 16px;
  text-align: center;


}

.gradient-section{
  align-items: center;
}

.gradient-section img{
  align-items: center;
}



.gradient-section-2 h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: white;
  text-align: center;
  font-weight: 500;
}


.gradient-section-2 p{

  font-size: 16px;
  text-align: center;


}

.gradient-section-2{
  align-items: center;
}






.gradient-section-3 h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: white;
  text-align: center;
  font-weight: 500;
}


.gradient-section-3 p{

  font-size: 16px;
  text-align: center;


}

.gradient-section-3{
  align-items: center;
}


.close-lightbox {
  position: absolute;
  top: 15px;
  right: 30px;
  background: transparent;
  color: #fff;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
  transition: color 0.3s ease;
}


}













.custom-shape-divider-top-1730048845 {
  margin-bottom: -70px;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1730048845 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 72px;
}

.custom-shape-divider-top-1730048845 .shape-fill {
  fill: #0F0F0F;
}



.custom-shape-divider-top {
  margin-top: -70px;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg); /* Rotate the shape */
  transform-origin: center; /* Ensure it rotates around its center */
}


.custom-shape-divider-top svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 72px;
}

.custom-shape-divider-top .shape-fill {
  fill: #0F0F0F
}





.custom-shape-divider-top-sections {
  margin-bottom: -70px;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0; /* Rotate the shape */
  transform-origin: center; /* Ensure it rotates around its center */
}


.custom-shape-divider-top-sections svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 72px;
}

.custom-shape-divider-top-sections .shape-fill {
  fill: #0F0F0F
}




.custom-login-button {
  background-color: #0033ff; /* Example: Blue background */
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
}



.custom-signup-button {
  background-color: #0ae80d; /* Example: Green background */
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
}










.chat-widget img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.chat-widget:hover {
  background-color: #ffffff;
  color: black;
}

/* Lightbox Overlay */
.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Keep the existing background overlay */
  display: flex;
  align-items: flex-start; /* Align items at the top */
  justify-content: center;
  z-index: 1001;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  padding: 20px; /* Ensure spacing around the content */
}

/* Lightbox Content */
.lightbox-content {
  width: 100%;
  max-width: 600px;
  position: relative;
  border-radius: 12px;
  margin: auto;
}

.hero h1 .line-break,
.hero p .line-break {
  display: inline; /* Default: no break on mobile */
}

@media only screen and (min-width: 1024px) { /* Desktop styles */
  .hero h1 .line-break,
  .hero p .line-break {
    display: block; /* Forces a line break on desktop */
  }
}

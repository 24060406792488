.react-datepicker {
    background-color: #333;
    border: 1px solid #444;
    color: #fff;
  }
  
  .react-datepicker__header {
    background-color: #444;
    border-bottom: 1px solid #555;
  }
  
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: #fff;
  }
  
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: #fff;
  }
  
  .react-datepicker__day:hover,
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background-color: #007bff;
    color: #fff;
  }
  
  
  
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    background-color: #333333;
  }
  
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box li.react-datepicker__time-list-item--selected {
    background-color: #444444;
    color: #fff;
  }

  /* Style for blocked or disabled dates */
.react-datepicker__day--disabled {
    color: #777;
    background-color: #333; /* Optional: Keep the background consistent */
    cursor: not-allowed; /* Change cursor to indicate it's not clickable */
    opacity: 0.5; /* Make the disabled date appear less prominent */
  }
  
  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    border-color: #ccc;
  }
  
/* AdminDashboard.css */

.admin-dashboard {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

.admin-dashboard h1 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.initialize-payouts-button,
.confirm-payouts-button {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.initialize-payouts-button:disabled,
.confirm-payouts-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.initialize-payouts-button:hover:not(:disabled),
.confirm-payouts-button:hover:not(:disabled) {
  background-color: #0056b3;
}

.summary-section {
  margin-top: 20px;
}

.summary-section h2 {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.summary-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.summary-table th,
.summary-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.summary-table th {
  background-color: #f4f4f4;
  font-weight: bold;
  color: #333;
}

.summary-table tr:hover {
  background-color: #f9f9f9;
}

.summary-table td {
  color: #555;
}

.log-section {
  margin-top: 20px;
}

.log-section h2 {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.log-messages {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  max-height: 300px;
  overflow-y: auto;
  font-size: 14px;
  color: #444;
}

.log-messages p {
  margin: 5px 0;
  padding: 5px;
  border-left: 3px solid transparent;
}

.log-messages p:nth-child(odd) {
  background-color: #f9f9f9;
}

.log-messages p:nth-child(even) {
  background-color: #fff;
}

.log-messages p:before {
  content: "• ";
  font-weight: bold;
}

.log-messages p.success {
  border-left-color: #28a745;
  color: #28a745;
}

.log-messages p.warning {
  border-left-color: #ffc107;
  color: #856404;
}

.log-messages p.error {
  border-left-color: #dc3545;
  color: #dc3545;
}

@media (max-width: 768px) {
  .admin-dashboard {
    padding: 10px;
  }

  .initialize-payouts-button,
  .confirm-payouts-button {
    width: 100%;
    margin: 5px 0;
  }

  .summary-table th,
  .summary-table td {
    padding: 8px;
  }

  .log-messages {
    font-size: 12px;
  }
}

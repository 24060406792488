.color-wheel-builder {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.color-wheel-container {
  width: 100%;
  max-width: 750px; /* Optional maximum width */
  margin: 0 auto;
  padding-top: 0%;
}

.color-wheel-content {
  position: relative;
  width: 100%;
  padding-top: 100%; /* Maintains 1:1 aspect ratio */
}

.color-wheel-image,
#nodes-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.circle-button,
.sub-node,
.add-conf-button {
  position: absolute;
  transform: translate(-50%, -50%) scale(1);
  /* Sizes will be set dynamically in JavaScript */
}

.circle-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}

.node-label {
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  transition: transform 0.2s ease-in-out; /* Smooth label movement on hover */
}

.delete-button {
  position: absolute;
  bottom: -10px; /* Position below the text */
  background: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none; /* Prevent interactions when invisible */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease; /* Fade in/out smoothly */
}

.sub-node {
  width: 40px;
  height: 0px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-conf-button {
  background-color: rgba(128, 128, 128, 0.5);
  color: #fff;
  border: 2px dashed #ccc;
  cursor: pointer;
  display: none;
}

.confidence-label {
  text-align: center;
  color: #ffffff;
  transition: transform 0.2s ease-in-out;
  font-weight: 800;
}

.delete-conf-button {
  position: absolute;
  bottom: -10px; /* Position below the text */
  background: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none; /* Prevent interactions when invisible */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease;
}

.circle-button:hover .delete-button {
  opacity: 1;
  pointer-events: auto; /* Allow interactions when visible */
  transform: translateY(-25px);
}


.sub-node:hover .delete-conf-button {
  opacity: 1;
  pointer-events: auto; /* Allow interactions when visible */
}



.circle-button:hover,
.sub-node:hover {
  cursor: pointer;
}

/* Add smooth scaling transition */
.circle-button,
.sub-node {
  transition: transform 0.1s ease-out;
}

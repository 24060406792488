.about-page-wrapper {
  min-height: 100vh;
  position: relative;
}

.about-container {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  padding: 2rem;
  position: relative;
  margin-bottom: 200px;
}

.about-layout {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 2rem;
  max-width: 1200px;
  width: 100%;
}

.scrolling-polls-vertical-container {
  width: 290px;
  height: 100%;
}

.combined-sections-container {
  background-color: #161616;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 2rem;
  border-radius: 12px;
    max-width: 800px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: white;
  text-align: left;
}

.about-para-section {
  margin-bottom: 2rem;
}

.about-para-section:last-child {
  margin-bottom: 0;
}

.text-content h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.text-content p {
  font-size: 1rem;
  line-height: 1.6;
}

.opinari-footer {
  background-color: rgba(40, 40, 40, 0.8);
  padding: 1rem 0;
  width: 100%;
}

@media (max-width: 768px) {
  .about-container {
    padding: 20px 10px;
  }

  .about-layout {
    display: flex;
    flex-direction: column;
  }

  .scrolling-polls-vertical-container {
    width: 100%;
    height: 200px;
  }

  .combined-sections-container {
    padding: 1.5rem;
    text-align: center;
  }

  .text-content h2 {
    font-size: 1.75rem;
  }

  .text-content p {
    font-size: 0.9rem;
  }
}
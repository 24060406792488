.advertisements-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 20px;
}

.ad-boxes{
  display: contents;
}

.query-info {
  font-size: 16px;
  color: #a5a5a5;
}

.ad-box {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
  width: 98%; /* Optional: set a fixed width */
  height: auto; /* Optional: maintain aspect ratio */
}

.ad-box img {
  width: 100%;
  height: auto;
  object-fit: contain;
  transition: transform 0.1s ease, filter 0.1s ease; /* Add smooth transitions */
}

.ad-box.clicked img {
  filter: brightness(0.5); /* Darkens the image */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 18px;
  font-weight: bold;
  pointer-events: none;
  opacity: 0; /* Hidden by default */
  transition: opacity 0.3s ease;
}

.ad-box.clicked .overlay {
  opacity: 1; /* Show the overlay on click */
}

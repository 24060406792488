.revenue {
  position: relative;
  color: #fff;
  font-family: Arial, sans-serif;
}

.bento-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 20px;
  height: 100%;
  width: 100%;
  opacity: 0.2; /* Grey out the entire section */
  pointer-events: none; /* Disable interactions */
}

.bento-item {
  background: #1f1f1f;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.bento-large {
  grid-column: span 3;
  grid-row: span 2;
}

.bento-medium {
  grid-column: span 2;
  grid-row: span 1;
}

.bento-small {
  grid-column: span 1;
  grid-row: span 1;
}

.coming-soon-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0); /* Dark transparent overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  pointer-events: none;
}

.coming-soon-overlay h1 {
  font-size: 2rem;
  color: #fff;
  text-align: center;
  opacity: 0.9;
}

/* Grid Container */
.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

/* Individual Card */
.revenue-card {
  position: relative;
  background: #191919;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: left;
  font-family: 'Poppins';
}

/* Poll Question */
.poll-rev-question {
  font-size: 1.2em;
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 20px;
}

/* Graph Placeholder */
.graph-placeholder {
  height: 100px;
  background: #121212;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  color: #aaa;
}

/* Stats Section */
.stats p {
  color: #a5a5a5;
    margin: 5px 0;
}

/* Revenue Amount */
.revenue-amount {
  color: #64d169;
  font-size: 1.4em;
  font-weight: 600;
}

/* Tag ID */
.tag-id {
  font-size: 0.8em;
  color: #888;
  margin-top: 10px;
}

.graph-container {
  margin-bottom: 15px;
}



.total-earnings {
  text-align: center;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
}

.total-earnings h1 {
  text-align: left;
  font-size: 2em;
  font-weight: 500;
  color: #fff;
}

.total-earnings .earnings-amount {
  color: #64d169;
}



.overall-graph-container {
  margin: 20px 0;
  padding: 20px;
  background-color: #191919;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.overall-graph-container h3 {
  color: #fff;
  margin-bottom: 10px;
}




/* Redirect Button Container */
.redirect-icon-container {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

/* Redirect Icon */
.redirect-icon {
  color: #888;
  font-size: 16px;
  transition: color 0.2s ease;
}

.redirect-icon-container:hover .redirect-icon {
  color: #4caf50; /* Green color on hover */
}

/* Dropdown Container Styling */
.filters {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

/* Base Dropdown Styling */
.filters select {
  background-color: #191919; /* Match card background */
  color: #fff; /* White text */
  border: 1px solid #444; /* Border for dropdown */
  border-radius: 5px; /* Rounded corners */
  padding: 8px 12px; /* Padding inside the dropdown */
  font-size: 14px; /* Font size */
  font-family: 'Poppins', sans-serif; /* Match the font */
  appearance: none; /* Remove default dropdown arrow styling */
  cursor: pointer; /* Pointer cursor for interaction */
  transition: all 0.2s ease-in-out; /* Smooth transition on hover */
}

/* Dropdown Hover and Focus */
.filters select:hover,
.filters select:focus {
  border-color: #5c5c5c; /* Green border on hover/focus */
  outline: none; /* Remove focus outline */
}

/* Dropdown Arrow */
.filters select::after {
  content: '▼'; /* Custom arrow icon */
  position: absolute;
  right: 12px;
  pointer-events: none;
}

/* Adjust Dropdown Width */
.filters select {
  min-width: 150px; /* Ensure enough width for options */
}


.stat-divider {
  border: none;
  border-top: 1px solid #444; /* Thin, subtle divider */
  margin: 10px 0; /* Add spacing above and below the divider */
}




.insights-container {
  margin-left: 20px;
  padding: 20px;
  background-color: #1e1e1e;
  border-radius: 10px;
  color: #fff;
}

.insights-block {
  margin-bottom: 20px;
}

.insights-block h4 {
  font-size: 1.2rem;
  color: #64d169;
  margin-bottom: 10px;
}

.insights-block ul {
  list-style: none;
  padding: 0;
}

.insights-block ul li {
  margin-bottom: 5px;
  font-size: 1rem;
}




.heatmap-container {
  margin-top: 20px;
  padding: 10px;
  background-color: #1e1e1e;
  border-radius: 10px;
  color: #fff;
}

.heatmap-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.heatmap-table th,
.heatmap-table td {
  padding: 10px;
  border: 1px solid #2a2a2a;
}

.heatmap-table th {
  background-color: #333;
  color: #64d169;
}

.heatmap-table td {
  background-color: #262626;
}

.heatmap-table tr {
  transition: background-color 0.3s;
}



/* Big Revenue Styling */
.big-revenue {
  text-align: left; /* Center the value */
  margin: 10px 0; /* Add spacing */
}

.big-revenue-value {
  font-size: 2.5em; /* Make it BIG */
  font-weight: 600; /* Extra bold */
  color: #64d169; /* Match the green design */
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6); /* Add slight shadow */
  display: inline-block;
}

/* Stats Container */
.stats-container {
  margin-top: 10px;
  color: #a5a5a5;
  font-size: 0.9em;
  line-height: 1.5;
}

.stats-container p {
  margin: 5px 0;
}

.stats-container strong {
  color: #ffffff; /* Make labels bold and white */
}


/* Grey Revenue Styling */
.grey-revenue {
  color: #888888; /* Grey color */
  text-shadow: none; /* Remove green shadow */
}

.refresh-button-container{
  background: #64d169;
  display: flex;
  border-radius: 5px;
  padding: 0px 20px;
}



/* Card with Zero Revenue */
.card-zero-revenue {
  opacity: 0.5; /* Reduce opacity */
  transition: opacity 0.3s ease; /* Smooth transition */
}



@media (max-width: 768px) {
  .total-earnings h1 {
    color: #fff;
    font-size: 1.5em;
    font-weight: 500;
    text-align: left;
}
}
.live-dashboard {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #0f0f0f;
  padding: 20px;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #0f0f0f;
  color: white;
  padding-bottom: 0px;
}

.qr-card-container {
  align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-grow: 0.1;
}


.report-container img{
  height: 100px;
}


.top-row-container .qr-card,
.top-row-container .stats-cards > .dashboard-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.qr-card {
  display: flex; /* Ensures it becomes a flex container */
  flex-direction: column; /* Aligns children vertically */
  justify-content: center; /* Centers the QR code vertically */
  height: 100%; /* Matches the height of adjacent cards */
}


.top-row-container {
  display: flex;
  gap: 20px;
  align-items: stretch; /* Make all items stretch to the height of the tallest item */
}


.top-row-container .stats-cards {
  flex: 2; /* Allow the stats cards to take up more space */
  display: flex;
  gap: 20px;
  flex-direction: row; /* Align the stats cards horizontally */
}
.stats-card {
  flex: 1; /* Ensure each stats card takes up equal space */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dashboard-card {
  background: #191919;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.qr-code-card {
  flex-grow: 1; /* Allows it to expand and fill the available space */
  background: #191919;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex; /* Use flexbox to align content */
  justify-content: center; /* Center the QR code horizontally */
  align-items: center; /* Center the QR code vertically */
}

.report-card {
  flex-grow: 1; /* Allows it to expand and fill the available space */
  background: #191919;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex; /* Use flexbox to align content */
  justify-content: center; /* Center the QR code horizontally */
  align-items: center; /* Center the QR code vertically */
}


.vote-summary {
  margin-top: 20px;
  font-size: 16px;
  color: white;
}

.vote-details {
  
  margin-top: 10px;
}



.vote-detail-item {
  display: flex;
  align-items: center;
  background: #212121;
  padding: 8px 12px;
  border-radius: 5px;
  gap: 10px;
  margin-bottom: 10px;
}

.vote-color-indicator {
  flex-shrink: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.vote-label {
  font-weight: bold;
  margin-right: 5px;
}

.vote-count,
.vote-percentage {
  color: #ccc;
}


.back-button {
  background-color: #212121;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    padding: 10px 20px;
}

.back-to-presentation {
  background-color: #212121;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  padding: 10px 20px;
}

.poll-list-card {
  text-align: center;
  padding: 30px;
}


.dashboard-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.pulse-dot {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 10px;
  height: 10px;
  background-color: #00FF00;
  border-radius: 50%;
  animation: pulse-dot 2s infinite;
}

@keyframes pulse-dot {
  0%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.5);
  }
}

.dashboard-card {
  position: relative; /* This provides a positioning context for the absolute elements within it */
  background: #191919;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dashboard-list-card {
  position: relative; /* This provides a positioning context for the absolute elements within it */
  background: #00000000;
  padding: 15px;
  text-align: left;
}

.dashboard-card.full {
  grid-column: span 2;
}

.vote-summary h2, .vote-summary p {
  margin: 5px 0;
}

.dashboard-title {
  font-size: 24px;
  margin-bottom: 15px;
}


.dashboard-poll-question {
  font-size: 20px;
  color: white;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 0px;
}

.placeholder-chart {
  height: 200px;
  background: #666; /* Placeholder for the chart */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
}

.report-container p {
  max-width: 300px;

}

.confidence-counts {
  margin-top: 10px;
  display: flex;
  justify-content: space-between; /* Space the text blocks equally */
  width: 100%;
}

.confidence-bar-wrapper {
  position: relative;
  width: 80%;
  margin: 20px auto 0;
}

.confidence-bar-container {
  margin: 20px auto 0; /* Add top margin and center the bar */
  height: 20px; /* Make the bar taller */
  border-radius: 15px; /* Round the edges */
  overflow: hidden;
  display: flex;
}

.confidence-segment {
  height: 100%;
  position: relative;
}

.confidence-vote-counts {
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
}

.confidence-vote-count {
  text-align: center;
}

.confidence-labels-container {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.confidence-label {
  text-align: center;
}
.confidence-vote-counts.single {
  justify-content: center;
}




.dashboard-poll-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); /* Keeps the grid layout */
  gap: 20px;
}

.dashboard-poll-list-item {
  padding: 10px;
  border: 1px solid #636363;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  color: white;
}

.dashboard-poll-list-item:hover {
  background: #3d3d3d;
}


button {
  background: none;
  border: none;
  color: #fff; /* Example color */
  cursor: pointer;
  font-size: 16px;
  text-align: left;
}

.placeholder-image {
  width: 100%;
  max-width: 300px;
  margin: 20px auto;
  display: block;
}




@media (max-width: 768px) {

  .top-row-container .qr-card,
.top-row-container .stats-cards > .dashboard-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%; /* Ensure the cards take up the full height */
  width: auto;
}
  .qr-code-card{
    display: none;
  }
  .top-row-container {
    flex-direction: column ;
    align-items: center;
    margin-bottom: 0px;
  }

  .top-row-container.qr-card,
  .top-row-container.stats-cards {
    flex: none;
    width: 100%;
    max-width: 100%; /* Ensure full width on mobile */
  }

  .stats-cards {
    flex-direction: column !important;
    gap: 20px;
    width: 100%;
  }

  .dashboard-poll-list {
    grid-template-columns: 1fr; /* Single column layout for mobile */
  }

  .dashboard-list-card {
    position: relative; /* This provides a positioning context for the absolute elements within it */
    background: #00000000;
    padding: 0px;
  }

 
}


.faq-page-wrapper {
  min-height: 100vh;
  position: relative;
}

.faq-page {
  font-family: 'Poppins', sans-serif;
  color: #f1f1f1;
  padding: 2rem;
  position: relative;
  z-index: 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 0px;
}

.faq-section-description {
  text-align: center;
  margin-bottom: 2rem;
  padding: 2rem;
  border-radius: 8px;
}

.faq-title {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.faq-intro {
  font-size: 1.1rem;
  color: #cccccc;
  max-width: 600px;
  margin: 0 auto;
}

.faq-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 2rem;
}

.faq-item {
  background-color: rgb(33 33 33 / 90%);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.faq-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(255, 255, 255, 0.1);
}

.faq-item-content {
  padding: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.faq-item-title {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #ffffff;
  transition: color 0.3s ease;
}

.faq-item-description {
  font-size: 0.9rem;
  color: #cccccc;
  flex-grow: 1;
  transition: color 0.3s ease;
}

.faq-item:hover .faq-item-content {
  background-color: rgba(255, 255, 255, 0.9);
}

.faq-item:hover .faq-item-title,
.faq-item:hover .faq-item-description {
  color: #000000;
}

@media (max-width: 768px) {
  .faq-grid {
    grid-template-columns: 1fr;
  }

  .faq-title {
    font-size: 2rem;
  }

  .faq-intro {
    font-size: 1rem;
  }

  .faq-item-title {
    font-size: 1.1rem;
  }

  .faq-item-description {
    font-size: 0.85rem;
  }
}
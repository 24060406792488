/* Container for the tabs */
.modern-tabs-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 16px 0;
    background-color: #121212; /* Seamless dark background */
    border-bottom: 1px solid #1f1f1f; /* Subtle border to separate sections */
    padding-top: 0px;
    padding-bottom: 0px;
    max-width: 600px;
  }
  
  /* Tab container with rounded corners and shadow */
  .modern-tabs {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 600px;
    background-color: #0f0f0f;
    /* border-radius: 8px; */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4); */
    overflow: hidden;
  }
  
  /* Individual tab styles */
  .modern-tab {
    flex: 1 1;
    text-align: center;
    padding: 12px 0;
    font-size: 16px;
    font-weight: 500;
    color: #a5a5a5;
    cursor: pointer;
    transition: color 0.3s ease;
    position: relative;
    z-index: 1;
    font-family: Poppins;
    font-weight: 400;
    padding-top: 0px;
  }
  
  /* Active tab color */
  .modern-tab.selected {
    color: #ffffff; /* Cyan accent color for active tab */
  }
  
  /* Tab indicator for the selected tab */
  .modern-tab-indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    background-color: #007eff; /* Cyan color for the indicator */
    transition: transform 0.3s ease, width 0.3s ease;
    z-index: 0; /* Ensure the indicator is below the tabs */
  }
  
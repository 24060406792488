/* Autofill styles remain global */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px #00000060 inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* Main Container */
.paypal-email__container {
  min-height: 100vh;
  display: flex;
  color: white;
}

/* Form Section */
.paypal-email__form-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.paypal-email__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
}

.paypal-email__left-col {
  flex: 1;
  min-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Cards Container */
.paypal-email__cards {
  display: flex;
  gap: 20px;
}

/* Button Wrapper */
.paypal-email__button-wrapper {
  background: #191919;
  border-radius: 8px;
  max-width: 350px;
  min-width: 400px;
  padding: 30px 30px 20px;
  text-align: left;
}

/* PayPal Card Styles */
.paypal-email__card {
  background: url('../assets/creditcard-bg.png') no-repeat center top;
  background-color: #191919;
  border-radius: 10px;
  color: #f5f5f7;
  min-width: 400px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  text-align: left;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  background-size: cover;
}

.paypal-email__card--disabled {
  filter: grayscale(100%);
  opacity: 0.6;
}

.paypal-email__card-header {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.paypal-email__logo {
  width: 40px;
  height: auto;
}

.paypal-email__card-content {
  margin-top: 10px;
}

.paypal-email__card-content h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
}

.paypal-email__divider {
  margin: 10px 0;
  height: 1px;
  background-color: #75798b;
}

/* Input and Button Styles */
.paypal-email__input {
  background-color: #1e1e1e;
  color: #ffffff;
  border: 1px solid #444;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  margin-bottom: 10px;
}

.paypal-email__input:disabled {
  background-color: #2c2c2c;
  color: #888;
  cursor: not-allowed;
}

.paypal-email__save-btn {
  align-items: center;
  background-color: #0c2c63;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  gap: 8px;
  padding: 10px 15px;
}

/* CSV Viewer */
.paypal-email__csv-viewer {
  width: 100%;
  max-width: 920px;}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
    /* Wrapper Section */
    .paypal-email__wrapper {
      flex-direction: column;
      padding: 10px;
    }


    .credit-card {
        background-color: #191919;
        border-radius: 10px;
        color: #f5f5f7;
        min-width: 0px;
        padding: 20px;
        display: flex
    ;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        text-align: left;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        background-size: cover;
    }
  
    /* Cards Section */
    .paypal-email__cards {
      flex-direction: column;
      width: 100%;
      gap: 20px;
    }
  
    /* Button Wrapper */
    .paypal-email__button-wrapper {
      padding: 20px;
      min-width: 0px;
    }

    .paypal-email-input {
        background-color: #1e1e1e;
        color: #ffffff;
        border: 1px solid #444;
        border-radius: 5px;
        padding: 10px;
        font-size: 16px;
        width: 90%;
        margin-bottom: 10px;
    }
  
    /* Card Styles */
    .paypal-email__card {
      width: 100%;
      max-width: 100%;
      padding: 20px;
    }
  
    /* Left Column */
    .paypal-email__left-col {
      width: 100%;
      min-width: auto; /* Removes fixed min-width */
      gap: 20px;
    }
  
    /* Input Fields */
    .paypal-email__input {
      font-size: 16px;
      padding: 10px;
    }
  
    /* PayPal Logo */
    .paypal-email__logo {
      width: 30px;
      height: auto;
    }
  
    /* Card Chip */
    .paypal-email__chip {
      width: 30px;
      margin-top: -200px;
    }
  
    /* CSV Viewer */
    .paypal-email__csv-viewer {
      width: 100%;
    }
  
    /* Dividers */
    .paypal-email__divider {
      margin: 10px 0;
      height: 1px;
      background-color: #75798b;
    }
  
    /* Header and Text */
    .paypal-email__button-wrapper h2 {
      font-size: 1.2rem;
      text-align: left;
    }

    .paypal-email__button-wrapper p {
        text-align: left;
      }

   
  }
  

/* Keep other necessary styles from original CSS but prefix with paypal-email__ */
/* Add any additional component-specific styles needed */